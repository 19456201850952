import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
    
        <h1> Build Pilot </h1>

        <p>
          Currenlty nothing to see here. But soon we will have a lot of fun stuff to play with.
        </p>
        
      </header>
    </div>
  );
}

export default App;
